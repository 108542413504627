import React from "react"
import LayoutDefault from "../components/layouts/default"

import Error404 from "../components/parts/body/errors/404"

export default () => (

  <LayoutDefault>
    <Error404></Error404>
  </LayoutDefault>

)
