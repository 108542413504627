import React from "react"
import { Link } from "gatsby"

export default ({ children }) => (

<div class="d-flex align-items-center u-bg-img-hero u-space-4 min-height-100vh--lg" style={{backgroundImage: 'error-404-flat-concept-illustration.svg'}}>
  <div class="container">
    <div class="w-lg-60 w-xl-50">
      <div class="mb-5">
        <h1 class="text-primary font-weight-normal">Page not <span class="font-weight-bold">found</span></h1>
        <p class="mb-0">Oops! Looks like you followed a bad link.</p>
        <p>If you think this is a problem with us, please <Link to="/">tell us</Link>.</p>
      </div>
      <Link to="/" className="btn btn-primary u-btn-primary u-btn-wide transition-3d-hover">Go Back</Link>
    </div>
  </div>
</div>

)
